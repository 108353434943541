<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear style="width: 180px;">
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="pricingName" label="规则名称">
						<a-input v-model:value="formState.pricingName" placeholder="请输入规则名称"></a-input>
					</a-form-item>

					<a-form-item label="创建时间" name="time" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
								
					<a-form-item class="ui-form__item" label="状态" name="status">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 190px;">
							<a-select-option :value="1">禁用</a-select-option>
							<a-select-option :value="0">启用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['cinema_policy_pricing_add']" type="primary" @click="onAdd">添加</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1000 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'status'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['cinema_policy_pricing_edit']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-if="record.isDisabled===0" @click="onDisable(record)" v-permission="['cinema_policy_pricing_disabled']">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-else @click="onDisable(record)" v-permission="['cinema_policy_pricing_open']">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['cinema_policy_pricing_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
										<div v-permission="['cinema_policy_pricing_sync']" v-if="!record.isDisabled" @click="onSyncPrice(record)">
											<a-menu-item>
												同步定价规则
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
		</a-spin>
	</div>
	<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getPricePolicyList, deletePricePolicy,updatePricePolicy,getCinemaAllList,syncPricing } from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
			temp
		},
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {},
				searchData: {},
				showModal: false,
				currentTime:0,
				time: [],
				list: [],
				columns: [{
					title: '所属影院',
					dataIndex: 'cinema'
				}, {
					title: '规则名称',
					dataIndex: 'pricingName'
				}, {
					title: '优先级',
					dataIndex: 'pricingLevel',
					width: 100
				}, {
					title: '创建时间',
					key: 'createTime'
				}, {
					title: '状态',
					key: 'status',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					width: 100,
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				isSee: false,
				id: 0
			}
		},
		created() {
			this.currentTime = this.moment().startOf('day').valueOf() / 1000;
			this.onSearch();
			this.getAllCinemaList()
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				// delete this.searchData.organizationId
				// this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.versionType = this.searchData.versionType ? this.searchData.versionType : undefined;
				this.searchData.isValid = this.searchData.isValid >= 0 ? this.searchData.isValid : undefined;
				this.searchData.isDisabled = this.searchData.isDisabled >= 0 ? this.searchData.isDisabled : undefined;
				if (this.time && this.time.length) {
					this.searchData.createStartTime = this.moment(this.time[0]).unix();
					this.searchData.createEndTime = this.moment(this.time[1]).unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.time = []
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getPricePolicyList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList() {
				this.cinemaAllList = [];
				let ret = await getCinemaAllList({})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data;
				}
			},
			onAdd() {
				this.id = 0;
				this.isEdit = false;
				this.isSee = false;
				this.showModal = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.isSee = false;
				this.showModal = true;
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deletePricePolicy({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onSyncPrice(item) {
				this.$confirm({
					title: '提示',
					content: "确定同步定价规则吗？",
					onOk: async () => {
						this.loading = true;
						try {
							let ret;
							ret = await syncPricing({
								cinemaId: item.cinemaId
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
		    async onDisable(record){
				this.$confirm({
					title: '提示',
					content: "确定" + (record.isDisabled === 0 ? '禁用' : '启用') + '吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret;
							if(record.isDisabled===1) {
								record.isDisabled = 0
							}else{
								record.isDisabled = 1
							}
							let postData = JSON.parse(JSON.stringify(record))
							ret = await updatePricePolicy({
								id: postData.id,
								isDisabled: postData.isDisabled
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
