<template>
	<div>
		<a-spin :spinning="loading">
			<span @click="onShowModal">
				<slot>
					<a-button type="primary">选择影片</a-button>
					<span v-if="selectedRowKeys.length !== 0"> 已选{{selectedRowKeys.length}}影片</span>
				</slot>
			</span>
			<a-modal v-model:visible="visible" width="900px" title="影片" :maskClosable="false" @cancel="onCancel"
				@ok="onOk">
				<a-spin :spinning="loading">
					<div>
						<a-form ref="form" name="form" :model="formState" @finish="onSearch">
							<a-row>
								<a-col :span="18">
									<a-form-item class="ui-form__item" label="影片名称" name="name">
										<a-input placeholder="请输入影片名称" v-model:value="formState.name" />
									</a-form-item>
								</a-col>
								<a-col :span="6" style="text-align: right;">
									<a-button type="primary" html-type="submit">搜索</a-button>
									<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
								</a-col>
							</a-row>
						</a-form>

						<div>
							<a-table :columns="columns" :dataSource="dataList" rowKey="id" :pagination="pagination"
								:rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
								:scroll="{ x: 500 }">
							</a-table>
						</div>
					</div>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		getFilmInfoList
	} from '@/service/modules/cinema.js';
	export default {
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: Array,
				default: () => {
					return []
				}
			},
			selectitems: {
				type: Array,
				default: () => {
					return []
				}
			},
			type: { // checkbox or radio
				type: String,
				default: 'checkbox'
			},
			id: { // 影院ID
				type: null,
				default: undefined
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			id(newVal) {
				this.pagination.current = 1;
				this.getData();
			},
			value(newVal) {
				this.selectedRowKeys = JSON.parse(JSON.stringify(newVal));
			},
		},
		created() {
			this.selectedRowKeys = this.value;
		},
		data() {
			return {
				formState: {},
				targetKeys: [],
				loading: false,
				visible: false,
				selectedRowKeys: [], // 选择的id集合
				selectedListData: [], // 选择的数据集合
				selectedListIdDataMap: {}, // 每选中一项时的数据对比字典，防止分页后数据丢失
				classifyList: [],
				dataList: [],
				columns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, {
					title: '影片名称',
					dataIndex: 'name'
				}, {
					title: '语言',
					dataIndex: 'language',
					width: 80,
					align: 'center'
				}],
				modelRef: {},
				searchData: {},
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getFilmInfoList({
						page: 1,
						pageSize: 99999,
						cinemaId: this.id,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						ret.data.list.forEach(item => {
							item.id = item.id.toString();
						})
						this.dataList = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onShowModal() {
				if (this.disabled) return;
				this.getData();
				this.visible = true;
				this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
				this.selectedListData = JSON.parse(JSON.stringify(this.selectitems));
			},
			onSelectChange(record, selected) {
				if (this.type === 'checkbox') {
					if (selected) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						this.selectedListData = this.selectedListData.filter(item => {
							return item.id !== record.id;
						});
					}
				} else {
					if (selected) {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record.id];
					} else {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			resetBtn() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			onCancel() {
				this.visible = false;
				this.targetKeys = JSON.parse(JSON.stringify(this.value));
			},
			onOk() {
				this.$emit('update:value', JSON.parse(JSON.stringify(this.selectedRowKeys)));
				this.$emit('change', JSON.parse(JSON.stringify(this.selectedListData)));
				this.visible = false;
			},
			onChange(e) {
				this.selectedRowKeys = [];
			}
		}
	}
</script>

<style scoped>
	.ui-form-item {
		margin-right: 20px;
	}
</style>